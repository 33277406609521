import axios from "axios";
import * as qs from "query-string";
import moment from "moment";

import { API_ENDPOINT, APP_ID, APP_SECRET, APP_VERSION_CODE } from "Config/AppConfig";
import { refreshToken } from "./auth";

import {
  REQUEST_TIME_OUT,
  STATUS_BAD_REQUEST,
  STATUS_INTERNAL_SERVER_ERROR,
  STATUS_OK,
  STATUS_UNAUTHORIZED
} from "Config/Remote";

import encryptMd5 from "Utils/encryptMd5";

const instance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: REQUEST_TIME_OUT,
  headers: {
    "App-Id": APP_ID,
    "App-Secret": APP_SECRET,
    "App-Version": APP_VERSION_CODE
    // Authorization: "Bearer c3fac1a2670644fd4f4123de498fc3d3",
    // "Origin": "http://localhost:3000"
  }
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // if (error.response?.status === 401) {
    //   if (!originalRequest._retry) {
    //     originalRequest._retry = true;
    //     const { access_token } = await refreshToken();
    //     axios.defaults.headers.common["Authorization"] =
    //       "Bearer " + access_token;
    //     return instance(originalRequest);
    //   } else {
    //     return (window.location = "/login");
    //   }
    // }

    return Promise.reject(error);
  }
);

const checkStatus = (response) => {
  console.log("RESPONSE:", response);
  if (response.status === STATUS_OK) {
    return response.data;
  }
  return {};
};

const logError = (error) => {
  console.log("ERROR RESPONSE:", error);
  console.log("ERROR RESPONSE:", error.response);
  if (error.response) {
    const { status, data } = error.response;
    if (status === STATUS_BAD_REQUEST) {
      return data;
    } else if (status === STATUS_UNAUTHORIZED) {
      return data;
    } else if (status === STATUS_INTERNAL_SERVER_ERROR) {
      return {
        data: "Mã lỗi" + status,
        msg: "Mã lỗi" + status,
        code: status
      };
    }
  }
  return error.response;
};

const getRequestConfig = (config = {}) => {
  const headers = config.headers ? config.headers : {};
  console.log("APP_VERSION_CODE", APP_VERSION_CODE)
  
  headers["Signature"] = encryptMd5(`APP|${APP_VERSION_CODE}|${moment().unix()}`);
  headers["App-Time-Now"] = `${moment().unix()}`
  config.headers = headers;
  return config;
};

export const GET = (url, params, config = {}) => {
  const queryString = qs.stringify(params);
  const urlWithQuery = `${url}?${queryString}`;
  const newConfig = getRequestConfig(config);
  return instance.get(urlWithQuery, newConfig).then(checkStatus).catch(logError);
};

export const POST = (url, params, config = {}) => {
  const newConfig = getRequestConfig(config);
  return instance.post(url, params, newConfig).then(checkStatus).catch(logError);
};

export const PUT = (url, params, config = {}) => {
  const newConfig = getRequestConfig(config);
  return instance.put(url, params, newConfig).then(checkStatus).catch(logError);
};

export const DELETE = (url, config = {}) => {
  const newConfig = getRequestConfig(config);
  return instance.delete(url, newConfig).then(checkStatus).catch(logError);
};

export const setAccessToken = (accessToken) => {
  instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setRefreshToken = (refreshToken) => {
  instance.defaults.headers.common["Refresh-Token"] = `${refreshToken}`;
};
