import { call, put } from "redux-saga/effects";
import API from "Services/API";
import CardActions from "Actions/CardActions";

// export function* validatePrepaid(action) {
//   const { params, onSuccess, onFailed } = action;
//   const response = yield call(API.checkValidatePrepaidCard, params);
//   if (response && response.status) {
//     yield put(CardActions.validatePrepaidSuccess(response));
//     onSuccess(response);
//   } else {
//     yield put(CardActions.validatePrepaidFailure(response));
//     onFailed(response);
//   }
// }

// export function* cardPayment(action) {
//   const { params, onSuccess, onFailed } = action;
//   const response = yield call(API.cardPayment, params);
//   if (response && response.status) {
//     yield put(CardActions.cardPaymentSuccess(response));
//     onSuccess(response);
//   } else {
//     yield put(CardActions.cardPaymentFailure(response));
//     onFailed(response);
//   }
// }

export function* cardActivate(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.cardActivate, params);
  if (response && response.status) {
    yield put(CardActions.cardActivateSuccess(response));
    onSuccess(response);
  } else {
    yield put(CardActions.cardActivateFailure(response));
    onFailed(response);
  }
}

export function* cardActivateOTP(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.cardActivateOTP, params);
  if (response && response.status) {
    yield put(CardActions.cardActivateOTPSuccess(response));
    onSuccess(response);
  } else {
    yield put(CardActions.cardActivateOTPFailure(response));
    onFailed(response);
  }
}