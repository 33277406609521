import LocalStorage from "Utils/LocalStorage/LocalStorage";
import { POST } from "./base";
import { setRefreshToken } from "./base";

export const login = (params) => {
  const path = "auth/login";
  const data = {
    username: params.username,
    password: params.password
  };
  return POST(path, data);
};

export const refreshToken = () => {
  const refreshToken = LocalStorage.get("Refresh-Token");
  setRefreshToken(refreshToken);
  const path = "auth/refresh";
  return POST(path);
};
