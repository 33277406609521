import { call, put } from "redux-saga/effects";
import API from "Services/API";
import VoucherActions from "Actions/VoucherActions";
import CardActions from "Actions/CardActions";

export function* validate(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.checkValidate, params);
  let errorIdx = -1;
  const res = response.length ? response[0] : response ;
  if(response.length){
    response.map((res, index) => {
      if(res.done === 0 && errorIdx === -1){
        errorIdx = index
      }
    })
  }

  if (res && errorIdx === -1) {
    if(res.is_card)
    {
      yield put(CardActions.validatePrepaidSuccess(res));
    } else {
      yield put(VoucherActions.validateSuccess(res));
    }
    onSuccess(res);
  } else {
    const errRes = errorIdx == -1 ? res : response[errorIdx]
    yield put(VoucherActions.validateFailure(errRes));
    onFailed(errRes);
  }
}

export function* activeVouchers(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.activeVouchers, params);

  if (response && response[0]){
    yield put(VoucherActions.activeVouchersSuccess(response[0]));
    onSuccess(response[0]);
  } else {
    yield put(VoucherActions.activeVouchersFailure(response));
    onFailed(response);
  }
}
