import { takeLatest, takeEvery, all } from "redux-saga/effects";
import { ActivateHistoryTypes } from "Reduxes/Actions/ActivateHistoryActions";
import { VoucherTypes } from "Actions/VoucherActions";
import { StartupTypes } from "Actions/StartupActions";
import { AuthTypes } from "Actions/AuthActions";
import { CardTypes } from "Actions/CardActions";

import { getActivateHistory } from "./ActivateHistorySagas";
import { activeVouchers, validate } from "Sagas/VoucherSagas";
import { cardActivate, cardActivateOTP } from "Sagas/CardSagas";
import { login } from "./AuthSagas";
import { startup } from "Sagas/StartupSagas";

export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(ActivateHistoryTypes.GET_ACTIVATE_HISTORY, getActivateHistory),
    takeLatest(VoucherTypes.VALIDATE, validate),
    takeLatest(VoucherTypes.ACTIVE_VOUCHERS, activeVouchers),
    // takeLatest(CardTypes.VALIDATE_PREPAID, validatePrepaid),
    // takeLatest(CardTypes.CARD_PAYMENT_REQUEST, cardPayment),
    takeLatest(CardTypes.CARD_ACTIVATE, cardActivate),
    takeLatest(CardTypes.CARD_ACTIVATE_OTP, cardActivateOTP)
  ]);
}
