function giftDetailModel(data = {}, card_id = 0, money = 0, card_type = 6) {
  return {
    avatar: data.avatar ? data.avatar : '',
    avatar_fm: data.avatar_fm ? data.avatar_fm : [],
    brand_id: data.brand_id ? data.brand_id : 0,
    brand_item_id: data.brand_item_id ? data.brand_item_id : '',
    cat_id: data.cat_id ? data.cat_id : 0,
    code_min: data.code_min ? data.code_min : 0,
    code_quantity: data.code_quantity ? data.code_quantity : 0,
    code_stock: data.code_stock ? data.code_stock : 0,
    code_willexpired: data.code_willexpired ? data.code_willexpired : 0,
    created: data.created ? data.created : 0,
    gift_id: data.gift_id ? data.gift_id : 0,
    id: data.id ? data.id : 0,
    image: data.image ? data.image : '',
    image_fm: data.image_fm ? data.image_fm : [],
    image_rectange: data.image_rectange ? data.image_rectange : '',
    image_rectange_fm: data.image_rectange_fm ? data.image_rectange_fm : [],
    inventory: data.inventory ? data.inventory : 0,
    isPhysical: data.isPhysical ? data.isPhysical : 1,
    isUnfixPrice: data.isUnfixPrice ? data.isUnfixPrice : 1,
    is_hot: data.is_hot ? data.is_hot : 0,
    justGetOrder: data.justGetOrder ? data.justGetOrder : 1,
    position: data.position ? data.position : 1,
    price: data.price ? data.price : 0,
    product_code: data.product_code ? data.product_code : '',
    product_id: data.product_id ? data.product_id : 0,
    quantity_expired: data.quantity_expired ? data.quantity_expired : 0,
    status: data.status ? data.status : 1,
    supplier_id: data.supplier_id ? data.supplier_id : 0,
    title:  data.isUnfixPrice == 2 ? `${data.title} ${money}` : (card_id > 0 ? (card_type === 5 ? '[UrBox] Phiếu quà tặng': '[UrBox] Gift Card ') : (data.title ? data.title : '')),
    title_detail: data.title_detail ? data.title_detail : '',
    type: data.type ? data.type : 1,
    unitPrice: data.unitPrice ? data.unitPrice : "",
    valuex: data.isUnfixPrice == 2 ?  money : (data.valuex ? data.valuex : 0),
    view: data.view ? data.view : 0
  };
}

export default giftDetailModel;
