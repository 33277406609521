import React, { useEffect, useState } from "react";
import BottomSheet from "Components/BottomSheet";
import "./index.scss";
import { isIos } from "Utils/platform";
import { Icon } from "antd-mobile";

const InstallPWA = ({ modalVisible = false }) => {
  const [promptInstall, setPromptInstall] = useState(null);
  const [modalInstall, setModalInstall] = useState(modalVisible);

  useEffect(() => {
    const listener = window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setPromptInstall(e);
      setModalInstall(true);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", listener);
    };
  }, []);

  useEffect(() => {
    if (modalVisible !== modalInstall) {
      setModalInstall(modalVisible);
    }
  }, [modalVisible]);

  function installApp() {
    if (promptInstall) {
      promptInstall.prompt();
      setModalInstall(false);

      promptInstall.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
        } else {
        }
        setModalInstall(false);
        setPromptInstall(null);
      });
    }
  }

  return (
    <BottomSheet
      isOpen={modalInstall}
      class="prompt"
      onBackdropClick={() => setModalInstall(false)}>
      <p className="content-prompt margin-50">
        Thêm App vào màn hình chính thiết bị
      </p>
      {isIos() ? (
        <div className={"instruction"}>
          <p className="content-prompt">
            Bấm nút "Chia sẻ" và chọn "Thêm vào màn hình chính"
          </p>
          <Icon type={"down"} size={"lg"} className={"icon-chevron"} />
        </div>
      ) : (
        <div onClick={installApp} className="button-install">
          Thêm
        </div>
      )}
    </BottomSheet>
  );
};

export default InstallPWA;
