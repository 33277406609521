import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./Utils/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./Services/serviceWorker";
import { icons } from "./Assets/icons";

React.icons = icons;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
});
// serviceWorker.unregister();
