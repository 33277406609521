import { login, refreshToken } from "./auth";
import { setAccessToken, setRefreshToken } from "./base";
import { getActivateHistory } from "./activate";
import { activeVouchers, checkValidate } from "./Voucher";
import { cardActivate, cardActivateOTP } from "./card";


const API = {
  setAccessToken: setAccessToken,
  setRefreshToken: setRefreshToken,
  login: login,
  refreshToken: refreshToken,
  getActivateHistory: getActivateHistory,
  checkValidate: checkValidate,
  activeVouchers: activeVouchers,
  // checkValidatePrepaidCard: checkValidatePrepaidCard,
  // cardPayment: cardPayment,
  cardActivate: cardActivate,
  cardActivateOTP: cardActivateOTP

};

export default API;
