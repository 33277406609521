import { call, put } from "redux-saga/effects";
import API from "Services/API";
import LocalStorage from "Utils/LocalStorage/LocalStorage";
import StartupActions from "Reduxes/Actions/StartupActions";

export function* startup(action) {
  const { callback = () => {} } = action;
  let isAuthenticated = false;
  const response = yield call(API.refreshToken);
  if (response && response.status) {
    const { is_authenticated, access_token, refresh_token } = response.data;
    isAuthenticated = is_authenticated;
    if (is_authenticated) {
      console.log("access_token", access_token);
      LocalStorage.set("Refresh-Token", refresh_token);
      API.setAccessToken(access_token);
      API.setRefreshToken(refresh_token);
    }
    yield put(StartupActions.startupSuccess(response));
  } else {
    yield put(StartupActions.startupFailed(response));
  }
  yield call(callback, isAuthenticated);
}
