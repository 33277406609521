import brandOfficeModel from './brandOfficeModel';

function userModel(data = {}) {
  return {
    brand_id: data.brand_id ? data.brand_id : 0,
    brand_user_id: data.brand_user_id ? data.brand_user_id : 0,
    brand_office: data.brand_office ? brandOfficeModel(data.brand_office) : {},
    brand_name: data.brand?.title ? data.brand.title : '',
    code: data.code ? data.code : '',
    created: data.created ? data.created : 0,
    id: data.id ? data.id : 0,
    name: data.name ? data.name : '',
    office_id: data.office_id ? data.office_id : 0,
    password: data.password ? data.password : '',
    role: data.role ? data.role : 0,
    status: data.status ? data.status : 0,
    supplier_id: data.supplier_id ? data.supplier_id : 0,
    token: data.token ? data.token : ''
  };
}

export default userModel;
