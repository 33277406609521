import { GET, POST } from "Services/API/base";
import { API_ENDPOINT_V4 } from "Config/AppConfig";

export const checkValidate = (params) => {
  const path = "/payment/check";
  params.amount = 0
  return POST(path, params, { baseURL: API_ENDPOINT_V4 });
};

export const activeVouchers = (params) => {
  const path = "payment/pay";
  return POST(path, params, { baseURL: API_ENDPOINT_V4 });
};
