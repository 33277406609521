import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StartupActions from "Reduxes/Actions/StartupActions";
import LaunchScreen from "Containers/LaunchScreen";
import { isIos, isNotInStandaloneMode } from "Utils/platform";
import InstallPWA from "Components/InstallPWA/InstallPWA";

const LoginPage = React.lazy(() => import("Containers/Login"));

const HomePage = React.lazy(() => import("Containers/Home"));

const FilterScreen = React.lazy(() => import("Containers/Filter"));

const ScanScreen = React.lazy(() => import("Containers/Scancode"));

const ActivePrepaidInput = React.lazy(() =>
  import("Containers/ActivePrepaidInput")
);
const OTPInput = React.lazy(() => import("Containers/OTP"));
const PrepaidActiveSuccess = React.lazy(() =>
  import("Containers/PrepaidActiveSuccess")
);
const ActiveVoucherScreen = React.lazy(() =>
  import("Containers/Voucher/Activation")
);

const ActiveVoucherSuccessScreen = React.lazy(() =>
  import("Containers/Voucher/ActiveSuccess")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function shouldOpenPrompt() {
  return isIos() && isNotInStandaloneMode();
}

export default function Router() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [modalVisible, setModalVisible] = useState(false);

  function callback() {
    setIsLoading(false);
  }

  useEffect(() => {
    if (shouldOpenPrompt()) {
      setModalVisible(true);
    }
    dispatch(StartupActions.startup(callback));
  }, []);

  return isLoading ? (
    <LaunchScreen />
  ) : (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/"
            name="Launch Screen"
            render={(props) => (
              <Redirect
                to={isAuthenticated ? "/voucher/scan" : "/login"}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/login"
            name="Login"
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            exact
            path="/home"
            name="Home"
            render={(props) => <HomePage {...props} />}
          />
          <Route
            exact
            path="/filter"
            name="Filter"
            render={(props) => <FilterScreen {...props} />}
          />
          <Route
            exact
            path="/voucher/active"
            name="Voucher Active"
            render={(props) => <ActiveVoucherScreen {...props} />}
          />
          <Route
            exact
            path="/voucher/success"
            name="Voucher Active Success"
            render={(props) => <ActiveVoucherSuccessScreen {...props} />}
          />
          <Route
            exact
            path="/voucher/scan"
            name="QR Scanner"
            render={(props) => <ScanScreen {...props} />}
          />
          <Route
            exact
            path="/card/active"
            name="Prepaid Card Active"
            render={(props) => <ActivePrepaidInput {...props} />}
          />
          <Route
            exact
            path="/card/active/otp"
            name="Prepaid Card Active OTP Input"
            render={(props) => <OTPInput {...props} />}
          />
          <Route
            exact
            path="/card/active/success"
            name="Prepaid Card Active Success"
            render={(props) => <PrepaidActiveSuccess {...props} />}
          />
        </Switch>
      </React.Suspense>
      <InstallPWA modalVisible={modalVisible} />
    </HashRouter>
  );
}
