import { call, put } from "redux-saga/effects";
import ActivateHistoryActions from "Reduxes/Actions/ActivateHistoryActions";
import API from "Services/API";

export function* getActivateHistory(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.getActivateHistory, params);
  if (response && response.status) {
    if (onSuccess) {
      yield call(onSuccess);
    }
    yield put(ActivateHistoryActions.getActivateHistorySuccess(response));
  } else {
    if (onFailed) yield call(onFailed);
    yield put(ActivateHistoryActions.getActivateHistoryFailed(response));
  }
}
