export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isNotInStandaloneMode = () => {
  const isStandAlone =
    "standalone" in window.navigator && window.navigator.standalone;
  return !isStandAlone;
};
