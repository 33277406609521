const myStorage = window.localStorage;
export default class LocalStorage {
  static set(key, data) {
    return myStorage.setItem(key, data);
  }

  static get(key, data) {
    return myStorage.getItem(key);
  }

  static remove(key) {
    return myStorage.removeItem(key);
  }

  static clear() {
    return myStorage.clear();
  }
}
