import Immutable from "seamless-immutable";
import { createReducer } from "reduxsauce";
import { AuthTypes } from "Reduxes/Actions/AuthActions";
import { StartupTypes } from "Reduxes/Actions/StartupActions";
import userModel from "Models/userModel";
export const INITIAL_STATE = Immutable({
  isAuthenticated: false,
  user: {},
  loginFetching: false
});

const loginRequest = (state, action) => {
  return state.merge({
    loginFetching: true
  });
};

const loginSuccess = (state, action) => {
  const { data } = action.response;
  const { user } = data;
  const { access_token } = data;
  userModel(user);

  return state.merge({
    isAuthenticated: true,
    token: access_token,
    user: userModel(user),
    loginFetching: false
  });
};

const loginFailed = (state, action) => {
  return state.merge({
    loginFetching: false,
    isAuthenticated: false
  });
};

const startupSuccess = (state, action) => {
  const { is_authenticated, user } = action.response.data;
  return state.merge({
    isAuthenticated: is_authenticated,
    user: userModel(user)
  });
};

const startupFailed = (state, action) => {
  return state.merge({
    isAuthenticated: false
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN_REQUEST]: loginRequest,
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGIN_FAILED]: loginFailed,
  [StartupTypes.STARTUP_SUCCESS]: startupSuccess,
  [StartupTypes.STARTUP_FAILED]: startupFailed
});
