import { GET, POST } from "Services/API/base";

// export const checkValidatePrepaidCard = (params) => {
//   const path = "/card/validate";
//   return GET(path, params, {});
// };

// export const cardPayment = (params) => {
//   const path = "/card/payment";
//   return POST(path, params);
// };

export const cardActivate = (params) => {
  const path = "/card/activate";
  return POST(path, params);
};

export const cardActivateOTP = (params) => {
  const path = "/card/activate/otp";
  return POST(path, params);
};