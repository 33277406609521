import { call, put } from "redux-saga/effects";
import AuthActions from "Reduxes/Actions/AuthActions";
import API from "Services/API";
import LocalStorage from "Utils/LocalStorage/LocalStorage";
export function* login(action) {
  const { params, onSuccess, onFailed } = action;
  const response = yield call(API.login, params);
  console.log("response", response);
  if (response && response.status) {
    const { access_token, refresh_token, is_authenticated } = response.data;
    if (is_authenticated) {
      if (onSuccess) {
        yield call(onSuccess);
      }
      LocalStorage.set("Refresh-Token", access_token);
      API.setAccessToken(access_token);
      API.setRefreshToken(refresh_token);

      yield put(AuthActions.loginSuccess(response));
    } else {
      if (onFailed) yield call(onFailed, response);
      yield put(AuthActions.loginFailed(response));
    }
  } else {
    if (onFailed) yield call(onFailed, response);
    yield put(AuthActions.loginFailed(response));
  }
}

export function* refreshToken(action) {
  const response = yield call(API.refreshToken);
  console.log("response", response);
  if (response && response.status) {
  }
}
