import giftDetailModel from './giftDetailModel';
import brandOfficeModel from './brandOfficeModel';

function codeModel(data = {}) {
  return {
    // active: data.active ? data.active : 1,
    // apiCall: data.apiCall ? data.apiCall : 0,
    // app_id: data.app_id ? data.app_id : 0,
    // bill_id: data.bill_id ? data.bill_id : '',
    // brand_code: data.brand_code ? data.brand_code : '',
    // brand_id: data.brand_id ? data.brand_id : 0,
    // brand_id_user: data.brand_id_user ? data.brand_id_user : 0,
    // brand_item_id: data.brand_item_id ? data.brand_item_id : '',
    // brand_office: data.brand_office ? brandOfficeModel(data.brand_office) : brandOfficeModel(),
    // brando_id: data.brando_id ? data.brando_id : 0,
    // brandt_id: data.brandt_id ? data.brandt_id : 0,
    // cart_detail_id: data.cart_detail_id ? data.cart_detail_id : 0,
    // cart_id: data.cart_id ? data.cart_id : 0,
    // code: data.code ? data.code : '',
    // code_using: data.code_using ? data.code_using : '',
    // created: data.created ? data.created : 0,
    // expired: data.expired ? data.expired : 0,
    // get_transaction_id: data.get_transaction_id ? data.get_transaction_id : '',
    // gift_detail: data.gift_detail ? giftDetailModel(data.gift_detail, data.card_id, data.money ) : giftDetailModel({}, data.card_id, data.money),
    // gift_detail_id: data.gift_detail_id ? data.gift_detail_id : 0,
    // gift_id: data.gift_detail ? data.gift_detail : 0,
    // id: data.id ? data.id : 0,
    // isEncrypt: data.isEncrypt ? data.isEncrypt : 1,
    // isPayed: data.isPayed ? data.isPayed : 1,
    // isReconciliation: data.isReconciliation ? data.isReconciliation : 1,
    // isTemp: data.isTemp ? data.isTemp : 1,
    // is_code: data.is_code ? data.is_code : false,
    // last_time: data.last_time ? data.last_time : 0,
    // order_detail_id: data.order_detail_id ? data.order_detail_id : 0,
    // order_id: data.order_id ? data.order_id : 0,
    // pin: data.pin ? data.pin : '',
    // pos_id: data.pos_id ? data.pos_id : 0,
    // process_crosscheck: data.process_crosscheck ? data.process_crosscheck : 1,
    // response_transaction_id: data.response_transaction_id ? data.response_transaction_id : '',
    // serial: data.serial ? data.serial : '',
    // status: data.status ? data.status : 1,
    // supplier_order_id: data.supplier_order_id ? data.supplier_order_id : 0,
    // supplierp_id: data.supplierp_id ? data.supplierp_id : 0,
    // used: data.used ? data.used : 0,
    // valid_time: data.valid_time ? data.valid_time : 0,
    // money: data.money ? data.money : 0
    amount: data.amount ? data.amount : 0,
    code: data.code ? data.code : '',
    brandName: data.brandName ? data.brandName : '',
    giftTitle: data.giftTitle ? data.giftTitle : ''
  };
}

export function codeModels(data) {
  let rs = []
  data.map(obj => {
    return rs.push(codeModel(obj))
  })
  return rs
}


export default codeModel;
