import get from "lodash/get";

function prepaidCardModel(data = {}) {
  const appTitle = get(data, 'app.title', data.appTitle || '[UrBox] Gift Card')
  return {
    appTitle,
    app_id: data.app_id ? data.app_id : 0,
    ban_time: data.ban_time ? data.ban_time : 0,
    created: data.created ? data.created : 0,
    customer_id: data.customer_id ? data.customer_id : 0,
    expired_time: data.expired_time ? data.expired_time : 0,
    fullname: data.fullname ? data.fullname : '',
    gift_id: data.gift_id ? data.gift_id : 0,
    id: data.id ? data.id : 0,
    money: data.money ? data.money : 0,
    needActive: data.needActive ? data.needActive : 2,
    number: data.number ? (data.number.substring(0, 2).toUpperCase() === 'UB' ? data.number : `UB${data.number}` ): '',
    order_detail_id: data.order_detail_id ? data.order_detail_id : 0,
    order_id: data.order_id ? data.order_id : 0,
    status: data.status ? data.status : 2,
    valid_time: data.valid_time ? data.valid_time : 0,
    wallet_id: data.wallet_id ? data.wallet_id : 0,
    user_id: data.user_id ? data.user_id : 0,
    type: data.type ? data.type : 6
  };
}

export default prepaidCardModel;
