import React, { useEffect, useState } from "react";
import "./index.scss";
import { ActivityIndicator } from "antd-mobile";

const LaunchScreen = props => {
  return (
    <div className="launch-page">
      <ActivityIndicator size={"large"} />
    </div>
  );
};

export default LaunchScreen;
