import { combineReducers } from "redux";
import createStore from "./CreateStore";
import rootSaga from "Sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { reducer as AuthReducer } from "Reducers/AuthReducer";
import { reducer as Vouchers } from "Reducers/VoucherReducer";
import { reducer as ActivateReducer } from "Reducers/ActivateReducer";
import { reducer as CardReducer } from "Reducers/CardReducer";
import ImmutablePersistenceTransform from "Services/ImmutablePersistenceTransform";

export const reducers = combineReducers({
  auth: AuthReducer,
  activate: ActivateReducer,
  vouchers: Vouchers,
  cards: CardReducer
});

export default () => {
  const rootReducer = persistReducer(
    {
      key: "root",
      storage,
      whitelist: ["vouchers"],
      transforms: [ImmutablePersistenceTransform]
    },
    reducers
  );
  let { store, sagasManager, sagaMiddleware } = createStore(
    rootReducer,
    rootSaga
  );
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("./").reducers;
      store.replaceReducer(nextRootReducer);
      const newYieldedSagas = require("../Sagas").default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }
  let persistor = persistStore(store);
  return { store, persistor };
};
