export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_KEY;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
export const PUSHER_APP_SECRET = process.env.REACT_APP_PUSHER_SECRET;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_ENDPOINT_V4 = process.env.REACT_APP_API_ENDPOINT_V4;

export const APP_ID = process.env.REACT_APP_ID;
export const APP_SECRET = process.env.REACT_APP_SECRET;
export const APP_VERSION_CODE = 1700;
export const APP_VERSION = "1.0.0";

// console.log("API API_ENDPOINT", API_ENDPOINT);

// if (ENVIRONMENT === "production") {
//   console.log = () => {};
// }
