import React from "react";
import "Styles/style.scss";
import "antd/dist/antd.css";
import "antd-mobile/dist/antd-mobile.css";
import { Provider } from "react-redux";
import createStore from "Reduxes";
import SnackbarProvider from "react-simple-snackbar";
import Router from "Navigation/Router";
import { PersistGate } from "redux-persist/integration/react";

export const { store, persistor } = createStore();

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
