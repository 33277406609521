import userModel from './userModel';
import giftDetailModel from './giftDetailModel';
import brandOfficeModel from './brandOfficeModel';
import get from "lodash/get";

function transactionModel(data = {}) {
  const appTitle = get(data, 'card.app.title', data.appTitle || '')
  return {
    appTitle,
    bill_id: data.bill_id ? data.bill_id : '',
    brand_id: data.brand_id ? data.brand_id : 0,
    brand_user: data.brand_user ? userModel(data.brand_user) : userModel(),
    brand_office: data.brand_office ? brandOfficeModel(data.brand_office) : brandOfficeModel(),
    brando_id: data.brando_id ? data.brando_id : 0,
    brandt_id: data.brandt_id ? data.brandt_id : 0,
    brandu_id: data.brandu_id ? data.brandu_id : 0,
    card_id: data.card_id ? data.card_id : 0,
    card_type: data.card_type ? data.card_type : 6,
    cart_detail_id: data.cart_detail_id ? data.cart_detail_id : 0,
    cart_id: data.cart_id ? data.cart_id : 0,
    code: data.code ? data.code : '',
    created: data.created ? data.created : 0,
    gift_detail: data.gift_detail ? giftDetailModel(data.gift_detail, data.card_id, data.money, data.card_type) : giftDetailModel({}, data.card_id, data.money, data.card_type),
    id: data.id ? data.id : 0,
    money: data.money ? data.money : 0,
    status: data.status ? data.status : 1,
    supplier_id: data.supplier_id ? data.supplier_id : 0
  };
}

export function transactionModels(data) {
  let rs = []
  data.map(obj => {
    return rs.push(transactionModel(obj))
  })
  return rs
}

export default transactionModel;
