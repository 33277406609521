import React from "react";
import "./index.scss";
import Sheet from "react-modal-sheet";

const BottomSheet = (props) => {
  const { isOpen, onClose, onBackdropClick, children } = props;
  return (
    <>
      <Sheet isOpen={isOpen} onClose={onClose}>
        <Sheet.Container>
          {/* <Sheet.Header /> */}
          <Sheet.Content>
            {children}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={onBackdropClick}
        />
      </Sheet>
    </>
  );
};

// BottomSheet.PropTypes = {
//   isOpen: bool,
//   onClose: func,
//   onBackdropClick: func
// }
//
// BottomSheet.defaultProps = {
//   isOpen: false,
//   onClose: ()=>{},
//   onBackdropClick: ()=>{}
// }

export default BottomSheet;
