function brandOfficeModel(data = {}) {
  return {
  id: data.id ? data.id : 0,
  address: data.address ? data.address : '',
  brand_id: data.brand_id ? data.brand_id : 0,
  city_id: data.city_id ? data.city_id : 29,
  district_id: data.district_id ? data.district_id : 0,
  ward_id: data.ward_id ? data.ward_id : 0
  };
}

export default brandOfficeModel;
